import React from "react";
import { useState } from "react";
import Select from "react-select";
import useButtons from "../../hooks/useButtons";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { postDataTo } from "../../_helper/fetcher";

export default function AssignPicker({ orderID, close, refetch, pickers }) {
  const [picker, setPicker] = useState({ value: "", label: "" });
  const { CloseButton, SaveButton } = useButtons();
  async function assignPicker(e) {
    e.preventDefault();
    await postDataTo(`orders/${orderID}/assign`, {
      assign_to: picker.value,
      note: e.target.note.value,
      status: "picking",
    });
    refetch();
    close();
  }
  const { mutate, isLoading } = useMutation({
    mutationFn: assignPicker,

    onError: (err) => toast.error(err.message),
  });
  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 z-[1000] grid place-items-center">
      <form
        onSubmit={mutate}
        className="bg-white rounded-md w-full max-w-xs p-4"
      >
        <div className="w-full">
          <label className="form-label">Select Picker*</label>
          <Select
            onChange={(e) => setPicker(e)}
            name="brand"
            className="react-select"
            options={pickers}
            value={picker}
          />
        </div>
        <div className="w-full">
          <label className="form-label">Note*</label>
          <textarea
            name="note"
            className="resize-none w-full h-28 name-input"
          ></textarea>
        </div>
        <div className="flex items-center justify-around mt-4">
          <CloseButton onClick={close} />
          <SaveButton isLoading={isLoading} text="Assign" />
        </div>
      </form>
    </div>
  );
}
