import React, { useState } from "react";
import DeletePopUp from "../../components/popups/DeletePopUp";
import Pagination from "../../components/shared/Pagination";
import useQueryString from "../../hooks/useQueryString";
import { useQuery } from "react-query";
import TableList from "../../components/shared/TableList";
import useRefetch from "../../hooks/useRefetch";
import { deleteDataFrom, fetchDataFrom } from "../../_helper/fetcher";
import { dateFormat } from "../../_helper/helper";
import { FaDownload } from "react-icons/fa";

const columnsHead = [
  { name: "id.", accessor: ["id"] },
  { name: "name", accessor: ["name"] },
];

export default function Exports() {
  const [pagin, setPagin] = useState({});

  const [showDeleteModal, setShowDeleteModal] = useState({
    id: "",
    show: false,
  });

  const { queryString } = useQueryString();
  const { refetchList } = useRefetch();

  async function getData() {
    const res = await fetchDataFrom("data/export-orders" + queryString);
    if (res.error) return null;
    const items = res.data;
    const { per_page, pages, total } = res.meta;
    setPagin({
      pages: pages,
      perPage: per_page,
      total: total,
    });
    return items;
  }

  async function deleteBrand() {
    await deleteDataFrom("data/export-orders/" + showDeleteModal.id);
    setShowDeleteModal({ id: "", show: false });
    refetchList("export-orders");
  }

  const {
    data: exports,
    isLoading,
    isError,
    error,
  } = useQuery(["export-orders", queryString], getData);

  return (
    <>
      <div className="px-2">
        <div className="box">
          <div className="box-content p-0">
            <div className="box-tools">
              <div className="box-title">
                <h3>Exported Orders</h3>
              </div>
            </div>

            <TableList
              onDelete={(item) => {
                setShowDeleteModal({
                  id: item.id,
                  show: true,
                });
              }}
              customColumns={[{ name: "Date" }]}
              customElements={[<Date />]}
              customButton={<DownloadButton />}
              data={exports}
              delPer="delete_brand"
              editPer="edit_brand"
              columns={columnsHead}
              isLoading={isLoading}
              isError={isError}
              error={error}
            />
          </div>
        </div>
        {showDeleteModal.show && (
          <DeletePopUp
            close={() => setShowDeleteModal({ id: "", show: false })}
            func={deleteBrand}
          />
        )}
      </div>
      {pagin?.perPage <= pagin?.total && <Pagination pagin={pagin} />}
    </>
  );
}

function Date({ item }) {
  return <>{dateFormat(item.created_at, "full")}</>;
}

function DownloadButton({ item }) {
  if (item?.status === 0) return "";

  function handleDownload() {
    window.open(item.path);
  }
  return (
    <button onClick={handleDownload} className="btn btn-icon btn-olive btn-sm">
      <FaDownload className="text-lg" />
    </button>
  );
}
