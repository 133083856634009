import React, { useState } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { FaSignOutAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "../Aside.css";

import { useAuth } from "../hooks/useAuth";
import LogoutPopup from "./popups/LogoutPopUp";
import { useEffect } from "react";
import { Fragment } from "react";

const Aside = ({ setCollapsed, collapsed, toggled, menus: sidebarMenus }) => {
  const [showModal, setShowModal] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const logOut = async () => {
    logout();
    navigate("/login");
  };

  function tryNavigate() {
    try {
      navigate(sidebarMenus[0][1][0]?.route);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (pathname === "/") {
      tryNavigate();
    }
  }, [pathname, sidebarMenus]);

  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      collapsedWidth={"5.75rem"}
    >
      <SidebarHeader>
        <div className="p-5">
          {collapsed ? (
            <img
              className="transition-all duration-300 ease-linear"
              style={{
                height: 30,
                width: 40,
              }}
              src="/image/logo_small.png"
              alt="Olive Logo"
            />
          ) : (
            <img
              className="transition-all duration-300 ease-linear w-[50%]"
              style={{
                height: 30,
              }}
              src="/image/olivego.png"
              alt="Olive Logo"
            />
          )}
        </div>
      </SidebarHeader>

      <SidebarContent
        onMouseEnter={() => {
          if (collapsed === true) {
            setCollapsed(false);
            setIsHover(true);
          }
        }}
        onMouseLeave={() => {
          if (collapsed === false && isHover) {
            setCollapsed(true);
            setIsHover(false);
          }
        }}
      >
        <Menu iconShape="round">
          {sidebarMenus?.map((menus) => {
            return (
              <Fragment key={menus[0]}>
                {!collapsed && (
                  <p className="text-logo_color pl-4 mt-6">{menus[0]}</p>
                )}
                {menus[1].map((menu) => (
                  <MenuItem
                    key={menu.route}
                    icon={menu.icon}
                    className={`hover:border-logo_color hover:bg-black hover:bg-opacity-90 border-gray-900  border-l-2 ${
                      pathname === menu.route ? "active-menu" : ""
                    }`}
                  >
                    <div className="text-logo_color">
                      <Link
                        className={`flex justify-between ${
                          pathname === menu.route ? "active-menu-link" : ""
                        }`}
                        to={menu.route}
                      >
                        <p className="w-full flex items-center justify-between">
                          {menu.name}
                          {menu?.count && menu?.count > 0 ? (
                            <span className="text-sm bg-logo_color text-black w-6 h-6 rounded-full font-bold grid place-items-center">
                              {menu?.count}
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                      </Link>
                    </div>
                  </MenuItem>
                ))}
              </Fragment>
            );
          })}

          <MenuItem
            onClick={() => setShowModal(!showModal)}
            icon={<FaSignOutAlt />}
          >
            Logout
          </MenuItem>
        </Menu>
        {showModal && <LogoutPopup setShowModal={setShowModal} func={logOut} />}
      </SidebarContent>
    </ProSidebar>
  );
};

export default Aside;
