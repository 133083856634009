import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";

import RouterConfig from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { store } from "./store";

const queryClient = new QueryClient();

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <RouterConfig />
        </Router>
        <ToastContainer hideProgressBar />
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
