import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../components/shared/ProtectedRoute";
const ControlDIYProjects = lazy(() =>
  import("../pages/DIYProjects/ControlDIYProjects")
);
const DIYCategories = lazy(() => import("../pages/DIYProjects/DIYCategories"));

const DIYProjectsList = lazy(() =>
  import("../pages/DIYProjects/DIYProjectsList")
);
export default function DIYRoutes() {
  return (
    <Routes>
      {/*<Route
        path="/installation-&-services/detail/:id"
        element={
          <ProtectedRoute>
            <InstalltionAndServiceDetail />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="projects"
        element={
          <ProtectedRoute permission="view_project">
            <DIYProjectsList />
          </ProtectedRoute>
        }
      />
      <Route
        path="projects/create"
        element={
          <ProtectedRoute permission="add_project">
            <ControlDIYProjects />
          </ProtectedRoute>
        }
      />
      <Route
        path="projects/edit/:id"
        element={
          <ProtectedRoute permission="edit_project">
            <ControlDIYProjects />
          </ProtectedRoute>
        }
      />
      <Route
        path="diy-categories"
        element={
          <ProtectedRoute permission="view_diy_category">
            <DIYCategories />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
