import axios from "axios";
import { toast } from "react-toastify";

export const clientAxios = axios.create({
  baseURL: "https://api.olivego.com/admin/",
});

clientAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem("acstkn");
  config.headers.authorization = `Bearer ${token}`;
  return config;
});

function catchError(err) {
  if (err?.response?.data?.message) {
    const message = err?.response?.data?.message;
    toast.error(message);
    return {
      error: true,
      message: message,
    };
  }
  toast.error(
    err.message ? err.message : "Unexpected error occurred. Try again."
  );
  return {
    error: true,
    message: err.message
      ? err.message
      : "Unexpected error occurred. Try again.",
  };
}

export async function fetchDataFrom(endpoint, headers = {}) {
  try {
    const { data } = await clientAxios.get(endpoint, { headers });
    if (data.result) {
      if (data.result.meta) {
        const { total_page } = data.result.meta;
        data.result.meta.pages = total_page;
      }
      return data.result;
    } else {
      toast.error(data.message);
      return { ...data, error: true };
    }
  } catch (err) {
    catchError(err);
  }
}

export async function postDataTo(endpoint, body) {
  try {
    const { data } = await clientAxios.post(endpoint, body);
    if (data.result) {
      toast.success(data?.message || "Success");
      return data.result;
    }
  } catch (err) {
    catchError(err);
  }
}

export async function editDataFrom(endpoint, body) {
  try {
    const { data } = await clientAxios.put(endpoint, body);
    if (data.result) {
      toast.success(data.message);
      return data.result;
    } else {
      toast.error(data.message);
      return { ...data, error: true };
    }
  } catch (err) {
    catchError(err);
  }
}

export async function deleteDataFrom(endpoint) {
  try {
    const { data } = await clientAxios.delete(endpoint);
    toast.success(data.message);
  } catch (err) {
    catchError(err);
  }
}
