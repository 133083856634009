import React from "react";

export default function CouponProductsPromo({ items = [] }) {
  return (
    <div className="mt-8">
      <h3 className="font-bold text-lg mb-4">Gifts By Using Coupon</h3>
      <div className="grid grid-cols-2 gap-4">
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className="flex items-center gap-4 bg-gray-100 p-4"
            >
              <img src={item?.image} alt="" className="w-14 aspect-square" />
              <p>{item?.name}</p>
              {/* {!product?.is_default && (
                    <div className="flex items-center gap-4 mt-2">
                      {variations?.map((variant) => {
                        const {
                          variation: { type_value, name },
                        } = variant;
                        if (type_value?.startsWith("#")) {
                          return (
                            <div
                              className="w-14 h-8 rounded-md"
                              style={{ backgroundColor: type_value }}
                              key={variant?.id}
                            ></div>
                          );
                        } else {
                          return (
                            <div
                              key={variant.id}
                              className="bg-gray-100 py-1 px-2 rounded-md"
                            >
                              {name}
                            </div>
                          );
                        }
                      })}
                    </div>
                  )} */}
            </div>
          );
        })}
      </div>
    </div>
  );
}
