import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useSearchParams } from "react-router-dom";
import useQueryString from "../../hooks/useQueryString";

const Pagination = ({ pagin }) => {
  const [currentPage, setCurrentPage] = useState();
  const { addQueryParams } = useQueryString();
  const pages = pagin.pages ? pagin.pages : 1;

  const [params] = useSearchParams();

  const pageParam = params.get("page");
  useEffect(() => setCurrentPage(pageParam ? pageParam - 1 : 0), [pageParam]);

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <Link
          to={pages - 1 > 0 && { search: `?page=${pages ? pages - 1 : 1}` }}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          {" "}
          Previous{" "}
        </Link>
        <Link
          to={
            !(pages === Math.ceil(pagin.total / pagin.perPage)) && {
              search: `?page=${pages ? pages + 1 : 2}`,
            }
          }
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          {" "}
          Next{" "}
        </Link>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          {/* <p className="text-sm text-gray-700">
            Showing
            <span className="font-medium">
              {' '}
              {!pageParam || +pageParam === 1
                ? 1
                : pageParam * pagin.perPage - pagin.perPage}{' '}
            </span>
            to
            <span className="font-medium">
              {' '}
              {!pageParam || +pageParam === 1
                ? 10
                : pageParam * pagin.perPage}{' '}
            </span>
            of
            <span className="font-medium"> {pagin.total} </span>
            results
          </p> */}
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <ReactPaginate
              activeClassName={"bg-black text-logo_color"}
              breakClassName={"item break-me "}
              pageLinkClassName="bg-grey-400 w-8 h-8 flex items-center justify-center border border-grey-600 rounded-sm"
              breakLabel={"..."}
              containerClassName={"flex items-center gap-2"}
              disabledClassName={"disabled-page"}
              onPageChange={({ selected }) =>
                addQueryParams("page", selected + 1)
              }
              forcePage={currentPage}
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageRangeDisplayed={5}
              pageCount={pagin.pages}
              renderOnZeroPageCount={null}
            />
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
