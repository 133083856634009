import { BsBoxSeam } from "react-icons/bs";
import {
  MdOutlineCategory,
  MdOutlineMapsHomeWork,
  MdPermMedia,
  MdAdminPanelSettings,
  MdReviews,
  MdHomeRepairService,
  MdOutlineContactSupport,
  MdCampaign,
} from "react-icons/md";
import {
  FaAward,
  FaExpandArrowsAlt,
  FaBarcode,
  FaGlobeAsia,
  FaCity,
  FaMapMarkedAlt,
  FaWarehouse,
  FaTags,
  FaSitemap,
  FaUsers,
  FaHammer,
  FaUsersCog,
  FaImage,
  FaFileInvoiceDollar,
  FaTools,
  FaTruck,
  FaMoneyBillAlt,
  FaBus,
  FaChartLine,
  FaBell,
  FaTextWidth,
  FaHistory,
  FaFileExport,
} from "react-icons/fa";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { GiReceiveMoney } from "react-icons/gi";
import { useEffect, useState } from "react";
import { fetchDataFrom } from "../_helper/fetcher";

export default function useSidebar() {
  const [counts, setCounts] = useState();
  async function getCounts() {
    const res = await fetchDataFrom("sidebar");
    setCounts(res);
  }
  useEffect(() => {
    getCounts();
  }, []);
  return {
    menus: [
      [
        "Dashboard",
        [
          {
            name: "Dashboard",
            route: "/dashboard",
            icon: <FaChartLine />,
            permission: "*",
          },
          {
            name: "Orders",
            route: "/orders",
            icon: <FaTruck />,
            permission: "view_order",
            count: counts?.pending_order_count,
          },
          {
            name: "Contacts",
            route: "/contacts",
            icon: <MdOutlineContactSupport />,
            permission: "*",
          },
          {
            name: "Notifications",
            route: "/notifications",
            icon: <FaBell />,
            permission: "*",
          },
        ],
      ],

      [
        "Product Menus",
        [
          {
            name: "Products",
            route: "/products",
            icon: <BsBoxSeam />,
            permission: "view_product",
          },

          {
            name: "Collections",
            route: "/collections",
            icon: <FaSitemap />,
            permission: "view_collection",
          },
          {
            name: "Categories",
            route: "/categories",
            icon: <MdOutlineCategory />,
            permission: "view_category",
          },
          {
            name: "Brands",
            route: "/brands",
            icon: <FaAward />,
            permission: "view_brand",
          },
          {
            name: "Variation Types",
            route: "/variation-types",
            icon: <VscTypeHierarchySub />,
            permission: "view_variation_type",
          },
          {
            name: "Variations",
            route: "/variations",
            icon: <FaExpandArrowsAlt />,
            permission: "view_variation",
          },
          {
            name: "SKU",
            route: "/sku",
            icon: <FaBarcode />,
            permission: "view_sku",
          },
          {
            name: "Warehouses",
            route: "/warehouse",
            icon: <FaWarehouse />,
            permission: "view_warehouse",
          },
          {
            name: "Media",
            route: "/media",
            icon: <MdPermMedia />,
            permission: "view_media",
          },
          {
            name: "Tags",
            route: "/tags",
            icon: <FaTags />,
            permission: "view_tag",
          },
          {
            name: "Reviews",
            route: "/customer-reviews",
            icon: <MdReviews />,
            permission: "view_review",
          },
        ],
      ],
      [
        "Exports",
        [
          {
            name: "Exported Orders",
            route: "/orders/exports",
            icon: <FaFileExport />,
            permission: "view_order",
          },
          {
            name: "Exported Products",
            route: "/products/exports",
            icon: <FaFileExport />,
            permission: "view_product",
          },
          {
            name: "Exported SKU",
            route: "/sku/exports",
            icon: <FaFileExport />,
            permission: "view_sku",
          },
          {
            name: "Exported Phase 2",
            route: "/sku/export-phase-2",
            icon: <FaFileExport />,
            permission: "view_sku",
          },
        ],
      ],
      [
        "Logs",
        [
          {
            name: "Order Logs",
            route: "/logs/orders",
            icon: <FaHistory />,
            permission: "view_order",
          },
          {
            name: "Product Logs",
            route: "/logs/products",
            icon: <FaHistory />,
            permission: "view_product",
          },
          {
            name: "SKU Logs",
            route: "/logs/suk",
            icon: <FaHistory />,
            permission: "view_sku",
          },
          {
            name: "Stock Logs",
            route: "/logs/stocks",
            icon: <FaHistory />,
            permission: "view_sku",
          },
        ],
      ],
      [
        "Promotions",
        [
          {
            name: "Categories",
            route: "/promotions/category",
            icon: <MdOutlineCategory />,
            permission: "view_promotion",
          },
          {
            name: "Campaign",
            route: "/promotions/campaign",
            icon: <MdCampaign className="text-xl" />,
            permission: "view_promotion",
          },
          {
            name: "Promotions",
            route: "/promotions/list",
            icon: <FaFileInvoiceDollar />,
            permission: "view_promotion",
          },
        ],
      ],
      [
        "Services",
        [
          {
            name: "Requests",
            route: "/services/requests",
            icon: <GiReceiveMoney />,
            permission: "view_request_services",
            count: counts?.service_request_count,
          },
          {
            name: "Services",
            route: "/services/list",
            icon: <MdHomeRepairService />,
            permission: "view_service",
          },
          {
            name: "Provider Requests",
            route: "/services/provider-requests",
            icon: <FaTools />,
            permission: "view_provider_requests",
          },
        ],
      ],
      [
        "DIY",
        [
          {
            name: "DIY Projects",
            route: "/diy/projects",
            icon: <FaHammer />,
            permission: "view_project",
          },
          {
            name: "DIY Categories",
            route: "/diy/diy-categories",
            icon: <MdOutlineCategory />,
            permission: "view_diy_category",
          },
        ],
      ],
      [
        "Location Menus",
        [
          {
            name: "Countries",
            route: "/location/countries",
            icon: <FaGlobeAsia />,
            permission: "view_country",
          },
          {
            name: "Regions",
            route: "/location/regions",
            icon: <FaMapMarkedAlt />,
            permission: "view_region",
          },
          {
            name: "Cities",
            route: "/location/cities",
            icon: <FaCity />,
            permission: "view_city",
          },
          {
            name: "Townships",
            route: "/location/townships",
            icon: <MdOutlineMapsHomeWork />,
            permission: "view_township",
          },
          {
            name: "Delivery Fees",
            route: "/location/deli-fees",
            icon: <FaMoneyBillAlt />,
            permission: "view_delivery_fee",
          },
          {
            name: "Dropshipping Fees",
            route: "/location/car-gates",
            icon: <FaBus />,
            permission: "view_delivery_fee",
          },
        ],
      ],

      [
        "Controls",
        [
          {
            name: "Banners",
            route: "/control/banners",
            icon: <FaImage />,
            permission: "view_banner",
          },
          {
            name: "Marquee Text",
            route: "/control/marquee-text",
            icon: <FaTextWidth />,
            permission: "*",
          },
          {
            name: "Customers",
            route: "/control/customers",
            icon: <FaUsers />,
            permission: "view_customer",
          },
          {
            name: "Admins",
            route: "/control/admins",
            icon: <FaUsersCog />,
            permission: "view_admin",
          },
          {
            name: "Permissions",
            route: "/control/permissions",
            icon: <MdAdminPanelSettings />,
            permission: "view_role",
          },
        ],
      ],
    ],
  };
}
