import React from "react";
import { FaBars } from "react-icons/fa";
import BackButton from "./shared/BackButton";

const Main = ({ handleCollapsedChange, children }) => {
  return (
    <main className="overflow-x-hidden">
      <div className="shadow-sm p-3 top-navbar flex items-center justify-between">
        <div
          className="btn-toggle"
          onClick={() => handleCollapsedChange((prev) => !prev)}
        >
          <FaBars />
        </div>
        <BackButton />
      </div>
      <div className="flex container p-5 flex-col relative">{children}</div>
      <footer>
        <small>
          © {new Date().getFullYear()} made with love by -{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://venuslab.co"
          >
            Venuslab.co
          </a>
        </small>
      </footer>
    </main>
  );
};

export default Main;
