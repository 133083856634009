import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permissions: [],
  name: null,
  email: null,
  isSuperAdmin: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      const { permissions, name, email, isSuperAdmin } = action.payload;
      state.permissions = permissions;
      state.name = name;
      state.email = email;
      state.isSuperAdmin = isSuperAdmin;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuth } = authSlice.actions;

export default authSlice.reducer;
