import React from "react";
import { useState } from "react";
import useButtons from "../../hooks/useButtons";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { editDataFrom, postDataTo } from "../../_helper/fetcher";

export default function ConfirmOrCancelModal({
  order,
  type = "confirm",
  close,
  refetch,
}) {
  const [note, setNote] = useState("");
  const { CloseButton, SaveButton } = useButtons();
  const [noteValue, setNoteValue] = useState("");
  async function assignPicker(e) {
    e.preventDefault();
    if (type === "confirm") await postDataTo(`orders/${order.id}/confirm`);
    else {
      if (noteValue === "")
        return toast.error("Please provide reason of cancellation");
      await handleSaveNote();
      await postDataTo(`orders/${order.id}/cancel`);
    }
    await postDataTo();
    refetch();
    close();
  }
  const { mutate, isLoading } = useMutation({
    mutationFn: assignPicker,

    onError: (err) => toast.error(err.message),
  });

  async function handleSaveNote() {
    await editDataFrom("/orders/" + order?.id, { admin_order_note: noteValue });
  }
  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 z-[1000] grid place-items-center">
      <div className="bg-white rounded-md w-full max-w-xs p-4">
        <h5
          className={`text-base text-center font-semibold ${
            type === "confirm" ? "" : "text-red-500"
          }`}
        >
          {type === "confirm" ? "Confirming" : "Cancelling"} order -{" "}
          {order?.order_number}?
        </h5>
        <p className="text-sm text-center my-4 max-w-[40ch]">
          You can't recall your action after clicking the 'Proceed' button
        </p>
        {type === "cancel" && (
          <input
            type="text"
            onChange={(e) => setNoteValue(e.target.value)}
            className="name-input"
            placeholder="Reason"
          />
        )}
        <div className="flex items-center justify-around mt-4">
          <CloseButton onClick={close} />
          <SaveButton isLoading={isLoading} text="Proceed" onClick={mutate} />
        </div>
      </div>
    </div>
  );
}
