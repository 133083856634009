import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Select from "react-select";

import useQueryString from "../../hooks/useQueryString";
import { memo } from "react";
import ClearFilterButton from "../shared/ClearFilterButton";
import useLocations from "../../hooks/select-hooks/useLocations";
import { useEffect } from "react";
import useDetailLocations from "../../hooks/select-hooks/useDetailLocations";
import CategoryDropDown from "../shared/CategoryDropDown";
import useCategories from "../../hooks/select-hooks/useCategories";

const statuses = [
  { value: "pending", label: "pending" },
  { value: "confirm", label: "confirm" },
  { value: "picking", label: "picking" },
  { value: "qc", label: "qc" },
  { value: "packing", label: "packing" },
  { value: "delivering", label: "delivering" },
  { value: "delivered", label: "delivered" },
  { value: "failed", label: "failed" },
  { value: "cancelled", label: "cancelled" },
];

const payments = [
  { value: "COD", label: "COD" },
  { value: "AYA_PAY", label: "AYA Pay" },
  { value: "KBZ_PAY", label: "KBZ PAY" },
  { value: "CB_PAY", label: "CB Pay" },
  { value: "UAB_PAY", label: "UAB Pay" },
  { value: "MPU", label: "MPU" },
];

function OrderListFilter() {
  const [searchParams] = useSearchParams();
  const statusParam = searchParams.get("status");
  const orderParam = searchParams.get("search");
  const phoneParam = searchParams.get("phone");
  const nameParam = searchParams.get("customer_name");
  const payParam = searchParams.get("payment_method");
  const regionParam = searchParams.get("region_id");
  const cityParam = searchParams.get("city_id");
  const townshipParam = searchParams.get("township_id");
  const startParam = searchParams.get("start_date");
  const categoryParam = searchParams.get("category_id");
  const endParam = searchParams.get("end_date");
  const [region, setRegion] = useState("");
  const [township, setTownship] = useState("");
  const [city, setCity] = useState("");
  const [category, setCategory] = useState({ value: "", label: "" });

  const [startDate, setStartDate] = useState(startParam || "");
  const [endDate, setEndDate] = useState(endParam || "");
  const [status, setStatus] = useState(
    statusParam ? statuses.find((sta) => sta.value === statusParam) : ""
  );
  const [pay, setPay] = useState(
    payParam ? payments?.find((pay) => pay.value === payParam) : ""
  );
  const { categories } = useCategories("one");

  const { addQueryParams } = useQueryString();
  const regions = useLocations("regions");
  const cities = useDetailLocations("regions/" + region.value);
  const townships = useDetailLocations("cities/" + city.value);

  function searchByOrderNumber(e) {
    e.preventDefault();
    addQueryParams("order_number", e.target.order.value);
  }
  function searchByPhoneNumber(e) {
    e.preventDefault();
    addQueryParams("phone", e.target.phone.value);
  }
  function searchByCustomerName(e) {
    e.preventDefault();
    addQueryParams("customer_name", e.target.name.value);
  }

  useEffect(() => {
    if (regions.length > 0 && regionParam) {
      if (regionParam)
        setRegion(regions?.find((region) => region.value === +regionParam));
    }
  }, [regions, regionParam]);
  useEffect(() => {
    if (cities.length > 0 && cityParam)
      setCity(cities?.find((ts) => ts.value === +cityParam));
  }, [cities, cityParam]);
  useEffect(() => {
    if (categoryParam && categories.length > 0)
      setCategory(categories?.find((ca) => ca.value === +categoryParam));
  }, [categories, categoryParam]);
  useEffect(() => {
    if (townships.length > 0 && townshipParam)
      setTownship(townships?.find((ts) => ts.value === +townshipParam));
  }, [townships, townshipParam]);

  return (
    <div className="flex items-start justify-between">
      <div className="grid grid-cols-3 items-center gap-x-10 gap-y-4 w-max mb-4">
        <div className="relative w-32">
          <Select
            onChange={(e) => {
              setStatus(e);
              addQueryParams("status", e.value);
            }}
            placeholder="Status"
            className="react-select capitalize"
            options={statuses}
            value={status}
          />
          <ClearFilterButton param_key="status" reset={() => setStatus("")} />
        </div>
        <div className="relative w-32">
          <Select
            onChange={(e) => {
              setPay(e);
              addQueryParams("payment_method", e.value);
            }}
            placeholder="Payment"
            className="react-select capitalize"
            options={payments}
            value={pay}
          />
          <ClearFilterButton
            param_key="payment_method"
            reset={() => setPay("")}
          />
        </div>
        <div className="relative w-32">
          <Select
            onChange={(e) => {
              setRegion(e);
              addQueryParams("region_id", e.value);
            }}
            placeholder="Region"
            className="react-select capitalize"
            options={regions}
            value={region}
          />
          <ClearFilterButton
            param_key="region_id"
            reset={() => setRegion("")}
          />
        </div>
        <div className="relative w-32">
          <Select
            onChange={(e) => {
              setCity(e);
              addQueryParams("city_id", e.value);
            }}
            placeholder="City"
            className="react-select capitalize"
            options={cities}
            value={city}
          />
          <ClearFilterButton param_key="city_id" reset={() => setCity("")} />
        </div>
        <div className="relative w-32">
          <Select
            onChange={(e) => {
              setTownship(e);
              addQueryParams("township_id", e.value);
            }}
            placeholder="Township"
            className="react-select capitalize"
            options={townships}
            value={township}
          />
          <ClearFilterButton
            param_key="township_id"
            reset={() => setTownship("")}
          />
        </div>
        <div className="w-[15rem] relative">
          <CategoryDropDown
            selected={category}
            categories={categories}
            control={(value, label) => {
              setCategory({ value, label });
              addQueryParams("category_id", value);
            }}
          />
          <ClearFilterButton
            param_key="category_id"
            reset={() => setCategory({ value: "", label: "" })}
          />
        </div>
      </div>
      <div className="w-full -mt-1 flex flex-col items-end justify-end gap-4">
        <div className="flex items-center gap-4">
          <form
            onSubmit={searchByOrderNumber}
            id="order_num"
            className="w-[11rem] relative"
          >
            <input
              type="text"
              className="form-input pr-6"
              placeholder="Search by order no."
              name="order"
              defaultValue={orderParam}
            />
            <button
              formTarget="order_num"
              className="absolute right-2 top-1/2 -translate-y-1/2"
            >
              <FaSearch />
            </button>
          </form>
          <form
            onSubmit={searchByCustomerName}
            id="name"
            className="w-[11rem] relative"
          >
            <input
              type="text"
              className="form-input pr-6"
              placeholder="Search by customer"
              name="name"
              defaultValue={nameParam}
            />
            <button
              formTarget="name"
              className="absolute right-2 top-1/2 -translate-y-1/2"
            >
              <FaSearch />
            </button>
          </form>
          <form
            onSubmit={searchByPhoneNumber}
            id="phone"
            className="w-[11rem] relative"
          >
            <input
              type="text"
              className="form-input pr-6"
              placeholder="Search by phone"
              name="phone"
              defaultValue={phoneParam}
            />
            <button
              formTarget="phone"
              className="absolute right-2 top-1/2 -translate-y-1/2"
            >
              <FaSearch />
            </button>
          </form>
        </div>
        <div className="flex items-center gap-2">
          <div className="relative">
            <input
              type="date"
              className="form-input"
              onChange={(e) => {
                setStartDate(e.target.value);
                addQueryParams("start_date", e.target.value);
              }}
              value={startDate}
            />
            <ClearFilterButton
              param_key="start_date"
              reset={() => setStartDate("")}
            />
          </div>
          to
          <div className="relative">
            <input
              type="date"
              className="form-input"
              onChange={(e) => {
                setEndDate(e.target.value);
                addQueryParams("end_date", e.target.value);
              }}
              min={startDate}
              value={endDate}
            />
            <ClearFilterButton
              param_key="end_date"
              reset={() => setEndDate("")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const MemoedFilter = memo(OrderListFilter);
export default MemoedFilter;
