import { lazy } from "react";
import ProtectedRoute from "../components/shared/ProtectedRoute";
import { Route, Routes } from "react-router-dom";

const Banners = lazy(() => import("../pages/Banners"));
const Marquee = lazy(() => import("../pages/MarqueeText"));
const Permissions = lazy(() => import("../pages/Permissions/Permissions"));
const PermissionsControl = lazy(() =>
  import("../pages/Permissions/PermissionsControl")
);
const AdminUsers = lazy(() => import("../pages/User/AdminUsers"));
const CustomersList = lazy(() => import("../pages/User/CustomerList"));

export default function ControlRoutes() {
  return (
    <Routes>
      <Route
        path="/banners"
        element={
          <ProtectedRoute permission="view_banner">
            <Banners />
          </ProtectedRoute>
        }
      />
      <Route
        path="/marquee-text"
        element={
          <ProtectedRoute>
            <Marquee />
          </ProtectedRoute>
        }
      />
      <Route
        path="/customers"
        element={
          <ProtectedRoute permission="view_customer">
            <CustomersList />
          </ProtectedRoute>
        }
      />

      <Route
        path="/admins"
        element={
          <ProtectedRoute permission="view_admin">
            <AdminUsers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/permissions"
        element={
          <ProtectedRoute permission="view_role">
            <Permissions />
          </ProtectedRoute>
        }
      />
      <Route
        path="/permissions/create"
        element={
          <ProtectedRoute permission="add_role">
            <PermissionsControl />
          </ProtectedRoute>
        }
      />
      <Route
        path="/permissions/edit/:id"
        element={
          <ProtectedRoute permission="edit_role">
            <PermissionsControl />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
