import React, { useState } from "react";
import { FaPaperPlane, FaSearch } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeletePopUp from "../components/popups/DeletePopUp";
import Pagination from "../components/shared/Pagination";
import useQueryString from "../hooks/useQueryString";
import { deleteDataFrom, fetchDataFrom } from "../_helper/fetcher";
import { useQuery } from "react-query";
import TableList from "../components/shared/TableList";
import { postDataTo } from "../_helper/fetcher";
import useButtons from "../hooks/useButtons";
import FormModalContainer from "../components/shared/FormModalContainer";
import NotificationForm from "../components/formPopups/NotificationForm";

const columnsHead = [
  { name: "id.", accessor: ["id"] },
  { name: "image", accessor: ["media", "thumbnail"] },
  { name: "title", accessor: ["title"] },
  { name: "type", accessor: ["type"] },
];

export default function Notifications() {
  const [pagin, setPagin] = useState({});

  const [showDeleteModal, setShowDeleteModal] = useState({
    id: "",
    show: false,
  });
  const [showModal, setShowModal] = useState({
    show: false,
    type: "create",
    data: {},
  });
  const [showDetailModal, setShowDetailModal] = useState({
    show: false,
    data: {},
  });

  const { queryString } = useQueryString();
  const { AddNewBtn, CloseButton } = useButtons();
  const [params] = useSearchParams();
  const pageParam = params.get("page");
  const nameParam = params.get("search");

  async function getData() {
    const res = await fetchDataFrom("notifications" + queryString);
    if (res.error) return null;
    const items = res.data;
    const { per_page, pages, total } = res.meta;
    setPagin({
      pages: pages,
      perPage: per_page,
      total: total,
    });
    return items;
  }

  const {
    data: notifications,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(["notifications", queryString], getData);
  const navigate = useNavigate();
  async function deleteNoti() {
    await deleteDataFrom("notifications/" + showDeleteModal.id);
    setShowDeleteModal({ id: "", show: false });
    refetch();
  }

  const searchByName = (e) => {
    e.preventDefault();
    if (e.target.name.value === "") {
      navigate({ search: pageParam ? "?page=" + pageParam : "" });
      return;
    }
    navigate({
      search: pageParam
        ? "?page=" + pageParam + "&search=" + e.target.name.value
        : "?search=" + e.target.name.value,
    });
  };

  return (
    <>
      <div className="px-2">
        <div className="box">
          <div className="box-content p-0">
            <div className="box-tools">
              <div className="box-title">
                <h3>Notifications List</h3>
              </div>
              <AddNewBtn
                permission="add_notification"
                onClick={() =>
                  setShowModal({
                    show: true,
                    type: "create",
                    data: {},
                  })
                }
                text="New Noti"
              />
            </div>
            {showModal.show && (
              <FormModalContainer
                title={
                  showModal.type === "create"
                    ? "New Notification"
                    : "Edit Notification"
                }
              >
                <NotificationForm
                  data={showModal.data}
                  type={showModal.type}
                  close={() => setShowModal({ show: false })}
                />
              </FormModalContainer>
            )}
            {showDetailModal.show && (
              <FormModalContainer title={"Notification Body"}>
                <div>{showDetailModal?.data?.body}</div>
                <div className="flex justify-end">
                  <CloseButton
                    onClick={() =>
                      setShowDetailModal({ show: false, data: {} })
                    }
                  />
                </div>
              </FormModalContainer>
            )}
            <div className="w-full gap-2 mb-2">
              <form
                onSubmit={searchByName}
                className="w-[17rem] relative ml-auto"
              >
                <input
                  defaultValue={nameParam}
                  type="text"
                  className="form-input pr-6"
                  placeholder="Search"
                  name="name"
                />
                <button className="absolute right-2 top-1/2 -translate-y-1/2">
                  <FaSearch />
                </button>
              </form>
            </div>
            <TableList
              data={notifications}
              columns={columnsHead}
              isLoading={isLoading}
              isError={isError}
              error={error}
              customColumns={[{ name: "body" }]}
              customElements={[<Body />]}
              customButton={<SendNoti />}
              onDetail={(item) =>
                setShowDetailModal({ show: true, data: item })
              }
              onDelete={(item) => {
                setShowDeleteModal({
                  id: item.id,
                  show: true,
                });
              }}
            />
          </div>
        </div>
        {showDeleteModal.show && (
          <DeletePopUp
            close={() => setShowDeleteModal({ id: "", show: false })}
            func={deleteNoti}
          />
        )}
      </div>
      {pagin?.perPage <= pagin?.total && <Pagination pagin={pagin} />}
    </>
  );
}

function Body({ item }) {
  return (
    <p className="w-[10rem] text-ellipsis overflow-hidden">{item?.body}</p>
  );
}

function SendNoti({ item }) {
  const [sending, setSending] = useState(false);
  async function sendNoti() {
    setSending(true);
    await postDataTo("send-notifications/" + item.id);
    setSending(false);
  }
  return (
    <button
      onClick={sendNoti}
      disabled={sending}
      className="btn btn-sm btn-olive disabled:brightness-75 flex items-center gap-1"
    >
      {sending ? "Sending..." : "Send"} <FaPaperPlane />
    </button>
  );
}
