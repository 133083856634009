import { lazy } from "react";
import ProtectedRoute from "../components/shared/ProtectedRoute";
import { Route, Routes } from "react-router-dom";

const ControlService = lazy(() =>
  import("../pages/InstalllationAndServices/ControlService")
);
const InstallationAndServicesList = lazy(() =>
  import("../pages/InstalllationAndServices/ServicesList")
);
const RequestedServices = lazy(() =>
  import("../pages/InstalllationAndServices/RequestedServices")
);

const RequestedServiceDetail = lazy(() =>
  import("../pages/InstalllationAndServices/RequestedServiceDetail")
);

const ProviderRequests = lazy(() =>
  import("../pages/InstalllationAndServices/ProviderRequests")
);
export default function ServiceRoutes() {
  return (
    <Routes>
      <Route
        path="/create"
        element={
          <ProtectedRoute permission={"create_service"}>
            <ControlService />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <ProtectedRoute permission={"edit_service"}>
            <ControlService />
          </ProtectedRoute>
        }
      />
      <Route
        path="/list"
        element={
          <ProtectedRoute permission="view_service">
            <InstallationAndServicesList />
          </ProtectedRoute>
        }
      />

      <Route
        path="/requests"
        element={
          <ProtectedRoute permission="view_request_services">
            <RequestedServices />
          </ProtectedRoute>
        }
      />
      <Route
        path="/requests/:id"
        element={
          <ProtectedRoute permission="view_service">
            <RequestedServiceDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/provider-requests"
        element={
          <ProtectedRoute permission="view_provider_requests">
            <ProviderRequests />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
