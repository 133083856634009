import { useEffect } from "react";
import { useState } from "react";
import { fetchDataFrom } from "../../_helper/fetcher";
import { makeItSelectable } from "../../_helper/helper";

const useCategories = (level, type = "categories") => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchDataFrom(type + "?limit=100&level=" + level).then((data) => {
      if (!data.error)
        setCategories(makeItSelectable(data.data, "subcategory"));
    });
  }, [type, level]);

  return { categories };
};

export default useCategories;
