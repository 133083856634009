import React, { Fragment, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";

export default function CategoryDropDown({
  categories = [],
  selected,
  control = () => {},
}) {
  const [show, setShow] = useState(false);
  const [showSub, setShowSub] = useState(null);
  const [subs, setSubs] = useState({ subs: [], parentID: null });
  return (
    <div className="relative">
      <input
        onChange={() => {}}
        required
        onFocus={() => setShow(true)}
        onBlur={() => setTimeout(() => setShow(false), 200)}
        name="category"
        className="name-input focus-within:ring-2 h-[38px] border cursor-default border-gray-300 placeholder:font-medium placeholder:text-gray-500"
        type="text"
        value={selected?.label}
        placeholder="Category"
        autoComplete="off"
      />
      <div className="absolute right-2 top-[10px] opaity-80 scale-[1.15] pl-2 border pointer-events-none border-transparent border-l-slate-400">
        <FiChevronDown className="focus-within:text-gray-600 text-gray-400 hover:text-gray-600" />
      </div>
      {show && (
        <div className="relative">
          <div className="w-full h-64 overflow-y-auto bg-white rounded-md flex flex-col gap-2 absolute text-gray-900 top-[calc(100%+1rem)] right-0 z-20 shadow-md border border-gray-200">
            {categories.map((category) => (
              <Fragment key={category.value}>
                <div
                  onClick={() => control(category.value, category.label)}
                  role="button"
                  onMouseEnter={() => {
                    setSubs({
                      subs: category.subcategory,
                      parentID: category.value,
                    });
                    setShowSub(category.value);
                  }}
                  type="button"
                  className="text-left px-4 hover:bg-gray-100 py-2 flex items-center justify-between gap-2 "
                >
                  {category.label}
                  <span className="text-gray-400">
                    {category.subcategory?.length > 0 && <FaChevronRight />}
                  </span>
                </div>
              </Fragment>
            ))}
          </div>
          {showSub === subs.parentID && subs.subs.length > 0 && (
            <Subcategory subs={subs.subs} control={control} />
          )}
        </div>
      )}
    </div>
  );
}

const Subcategory = ({ subs, control = () => {} }) => {
  const [showSubSub, setShowSubSub] = useState(null);
  return (
    <div className="w-max bg-white z-40 rounded-md flex flex-col gap-2 top-4 absolute left-[calc(100%+1rem)] text-gray-900 shadow-md border border-gray-200">
      {subs.map((sub) => (
        <Fragment key={sub.id}>
          <div
            role="button"
            onMouseEnter={() => setShowSubSub(sub.id)}
            type="button"
            onClick={() => control(sub.id, sub.name)}
            className="text-left px-4 hover:bg-gray-100 py-2 flex items-center justify-between gap-2 relative"
          >
            {sub.name}
            <span className="text-gray-400">
              {sub.subcategory?.length > 0 && <FaChevronRight />}
            </span>
          </div>
          <div className="h-[25rem] overflow-y-auto top-0 absolute left-[calc(100%+1rem)] z-20">
            {sub.subcategory?.length > 0 && sub.id === showSubSub && (
              <SubSubcategory subs={sub.subcategory} control={control} />
            )}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

const SubSubcategory = ({ subs, control = () => {} }) => {
  return (
    <div className="w-max  bg-white rounded-md flex flex-col gap-2 text-gray-900  shadow-md border border-gray-200">
      {subs.map((sub) => (
        <div
          onClick={() => control(sub.id, sub.name)}
          role="button"
          type="button"
          key={sub.id}
          className="text-left px-2 hover:bg-gray-100 py-2 flex items-center gap-2 relative"
        >
          {sub.name}
        </div>
      ))}
    </div>
  );
};
