import { lazy } from "react";
import ProtectedRoute from "../components/shared/ProtectedRoute";
import { Route, Routes } from "react-router-dom";
import ExportsPhase2 from "../pages/Skus/ExportsPhase2";

const Logs = lazy(() => import("../pages/Skus/Logs"));
const SKUExports = lazy(() => import("../pages/Skus/Exports"));
const ProductExports = lazy(() => import("../pages/Product/Exports"));
const Product = lazy(() => import("../pages/Product/Product"));
const Media = lazy(() => import("../pages/Media"));
const Categories = lazy(() => import("../pages/Categories"));
const VariationTypes = lazy(() => import("../pages/VariationTypes"));
const Variations = lazy(() => import("../pages/Variations"));
const Brand = lazy(() => import("../pages/Brand"));
const Tag = lazy(() => import("../pages/Tag"));
const Collections = lazy(() => import("../pages/Collections"));
const Skus = lazy(() => import("../pages/Skus"));
const ProductCreate = lazy(() => import("../pages/Product/ProductCreate"));
const ProductEdit = lazy(() => import("../pages/Product/ProductEdit"));
const WareHouseList = lazy(() => import("../pages/WareHouseList"));
const ProductReviewList = lazy(() => import("../pages/Product/ProductReview"));

export default function ProductRoutes() {
  return (
    <Routes>
      <Route
        path="/products"
        element={
          <ProtectedRoute permission="view_product">
            <Product />
          </ProtectedRoute>
        }
      />
      <Route
        path="/products/create"
        element={
          <ProtectedRoute permission="add_product">
            <ProductCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/products/exports"
        element={
          <ProtectedRoute permission="add_product">
            <ProductExports />
          </ProtectedRoute>
        }
      />
      <Route
        path="/products/edit/:id"
        element={
          <ProtectedRoute permission="edit_product">
            <ProductEdit />
          </ProtectedRoute>
        }
      />

      {/* <Route
    path="/products/:id"
    element={
      <ProtectedRoute permission=>
        <ProductDetail />
      </ProtectedRoute>
    }
  ></Route> */}
      <Route
        path="/collections"
        element={
          <ProtectedRoute permission="view_collection">
            <Collections />
          </ProtectedRoute>
        }
      />
      <Route
        path="/categories"
        element={
          <ProtectedRoute permission="view_category">
            <Categories />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/brands"
        element={
          <ProtectedRoute permission="view_brand">
            <Brand />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/variation-types"
        element={
          <ProtectedRoute permission="view_variation_type">
            <VariationTypes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/variations"
        element={
          <ProtectedRoute permission="view_variation">
            <Variations />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sku"
        element={
          <ProtectedRoute permission="view_sku">
            <Skus />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sku/exports"
        element={
          <ProtectedRoute permission="view_sku">
            <SKUExports />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sku/export-phase-2"
        element={
          <ProtectedRoute permission="view_sku">
            <ExportsPhase2 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sku/:id/logs"
        element={
          <ProtectedRoute permission="view_sku">
            <Logs />
          </ProtectedRoute>
        }
      />
      <Route
        path="/warehouse"
        element={
          <ProtectedRoute permission="view_warehouse">
            <WareHouseList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/media"
        element={
          <ProtectedRoute permission="view_media">
            <Media />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tags"
        element={
          <ProtectedRoute permission="view_tag">
            <Tag />
          </ProtectedRoute>
        }
      />
      <Route
        path="/customer-reviews"
        element={
          <ProtectedRoute permission="view_review">
            <ProductReviewList />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
