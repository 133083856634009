import { useNavigate } from "react-router-dom";
import { HiOutlineArrowCircleLeft } from "react-icons/hi";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      className="btn-md btn-olive flex items-center gap-2"
      type="button"
      onClick={() => navigate(-1)}
    >
      <HiOutlineArrowCircleLeft className="text-lg" />
      Back
    </button>
  );
};

export default BackButton;
