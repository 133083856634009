import { useDispatch } from "react-redux";
import { setAuth } from "../store/auth";
import { postDataTo } from "../_helper/fetcher";

export function useAuth() {
  const dispatch = useDispatch();

  async function handleLogin(body) {
    const res = await postDataTo("login", body);
    if (res.token && res.user) {
      let user = res.user;
      const authBody = {
        permissions: user.role.permissions,
        name: user.name,
        email: user.email,
        isSuperAdmin: user.role.permissions[0] === "*",
        authorized: true,
      };
      localStorage.setItem("acstkn", res.token);
      dispatch(setAuth(authBody));
      return { success: true };
    }
  }

  async function logout() {
    await postDataTo("logout");
    localStorage.removeItem("acstkn");
  }

  return {
    handleLogin,
    logout,
  };
}
