import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { fetchDataFrom } from "../../_helper/fetcher";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/shared/LoadingSpinner";

import { useReactToPrint } from "react-to-print";
import { FaFileInvoiceDollar, FaTruck } from "react-icons/fa";
import InvoicePrint from "../../components/order/InvoicePrint";
import ShippingLabel from "../../components/order/ShippingLabel";
import OrderProgress from "../../components/order/OrderProgress";
import AddressDetail from "../../components/order/AddressDetail";
import InvoiceDetail from "../../components/order/InvoiceDetail";
import OrderItems from "../../components/order/OrderItems";
import OperationNotes from "../../components/order/OperationNotes";
import { priceFormat } from "../../_helper/helper";
import CouponProductsPromo from "../../components/order/CouponProductsPromo";
import CouponServicesPromo from "../../components/order/CouponServicePromo";

export default function OrderDetail() {
  const { id } = useParams();
  const shippingLabelRef = useRef();
  const invoiceRef = useRef();

  const getData = async () => {
    const res = await fetchDataFrom("orders/" + id);
    if (res.error) return null;
    return res;
  };

  const { data: order, isLoading, isError } = useQuery(["order"], getData);
  const isDeliveryFree = useMemo(() => {
    if (order?.info?.length > 0) return order?.info[0].delivery_free;
    else return false;
  }, [order]);

  const handleShippingLabelPrint = useReactToPrint({
    content: () => shippingLabelRef.current,
    documentTitle: `Shipping Label - ${order?.order_number}`,
  });
  const handleInvoicePrint = useReactToPrint({
    content: () => invoiceRef.current,
    documentTitle: `Invoice - ${order?.order_number}`,
  });

  return (
    <section>
      {isLoading ? (
        <LoadingSpinner />
      ) : isError ? (
        <h3 className="text-red-400 text-lg text-center mt-20">
          Unexpected error occurred while getting the order detail
        </h3>
      ) : (
        <>
          <div className="hidden">
            <div ref={invoiceRef} className="p-6">
              <InvoicePrint order={order} />
            </div>
          </div>
          <div className="hidden">
            <div ref={shippingLabelRef}>
              <ShippingLabel order={order} />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold">
              Order Number - {order?.order_number}
            </h2>
            <div className="flex items-center gap-2">
              <button
                className="btn btn-sm btn-olive mr-2"
                onClick={handleShippingLabelPrint}
              >
                <FaTruck />
                Shipping Label
              </button>
              <button
                onClick={handleInvoicePrint}
                className="btn btn-sm btn-olive"
              >
                <FaFileInvoiceDollar /> Invoice
              </button>
            </div>
          </div>
          <OrderProgress
            assigns={order?.assigns}
            cancelled={order?.status === "cancelled"}
          />

          <div className="mt-4 grid grid-cols-2 items-start">
            <AddressDetail
              type={"Billing"}
              name={order?.billing_name || ""}
              phone={order?.billing_phone}
              email={order?.billing_email}
              address={order?.billing_address}
              location={order?.billing_township}
              city={order?.billing_city}
            />
            {order?.drop_shipping ? (
              <AddressDetail
                type={"Shipping"}
                name={order?.shipping_name || ""}
                phone={order?.shipping_phone}
                email={order?.shipping_email}
                address={order?.shipping_address}
                location={order?.shipping_township}
                drop_ship={order?.drop_shipping || null}
                city={order?.shipping_city}
              />
            ) : order?.diff_area_status === 1 ? (
              <AddressDetail
                type={"Shipping"}
                name={order?.shipping_name || ""}
                phone={order?.shipping_phone}
                email={order?.shipping_email}
                address={order?.shipping_address}
                location={order?.shipping_township}
                drop_ship={order?.drop_shipping || null}
                city={order?.shipping_city}
              />
            ) : (
              <AddressDetail
                type={"Shipping"}
                name={order?.billing_name}
                phone={order?.billing_phone}
                email={order?.billing_email}
                location={order?.billing_township}
                address={order?.billing_address}
                city={order?.billing_city}
              />
            )}
          </div>
          <InvoiceDetail
            deli_fee={
              isDeliveryFree
                ? "Free of charge"
                : priceFormat(order?.delivery_price)
            }
            total={order?.summary_price}
            status={order?.status}
            date={order?.created_at}
          />
          <OrderItems total={order?.summary_price} items={order?.info} />
          {order?.coupon_promo?.products?.length > 0 && (
            <CouponProductsPromo items={order?.coupon_promo.products} />
          )}
          {order?.coupon_promo?.services?.length > 0 && (
            <CouponServicesPromo items={order?.coupon_promo.services} />
          )}
          {order?.admin_order_note && (
            <div className="my-6">
              <h3 className="font-bold text-lg mb-4">Reason of cancellation</h3>
              <p>Reason : {order?.admin_order_note}</p>
            </div>
          )}
          {order?.order_note && (
            <div className="my-6">
              <h3 className="font-bold text-lg mb-4">
                Order Note from customer
              </h3>
              <p>Note : {order?.order_note}</p>
            </div>
          )}
          {order?.admin_order_note && (
            <div className="my-6">
              <h3 className="font-bold text-lg mb-4">Reason Of Cancellation</h3>
              <p>Reason : {order?.admin_order_note}</p>
            </div>
          )}
          <OperationNotes assigns={order?.assigns} />
        </>
      )}
    </section>
  );
}
