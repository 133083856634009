import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import SelectMedia from "./SelectMedia";

export default function UploadImage({ uploaded, current, setImage }) {
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(uploaded);
  return (
    <div>
      <div>
        <div>
          <label className="form-label">Upload Image</label>
          <div className="flex">
            <div>
              <div className="flex gap-4 items-center relative">
                <button
                  type="button"
                  onClick={() => setOpenMediaModal(true)}
                  htmlFor="brandImage"
                  className="w-20 h-20 bg-white p-5 rounded border-2 border-dashed flex cursor-pointer"
                >
                  <span className="m-auto text-gray-400 text-4xl">+</span>
                </button>
                {current ? (
                  <div className="relative group">
                    <button
                      onClick={() => setImage(null)}
                      className="absolute inset-0 bg-black bg-opacity-80 hidden place-items-center group-hover:grid text-red-500 z-20"
                      type="button"
                    >
                      <AiOutlineClose />
                    </button>
                    <img
                      src={current?.thumbnail}
                      className="w-20 h-20 rounded"
                      alt=""
                    />
                  </div>
                ) : uploadedImage ? (
                  <div className="relative group">
                    <button
                      onClick={() => setUploadedImage(null)}
                      className="absolute inset-0 bg-black bg-opacity-80 hidden place-items-center group-hover:grid text-red-500 z-20"
                      type="button"
                    >
                      <AiOutlineClose />
                    </button>
                    <img
                      src={uploaded?.thumbnail}
                      className="w-20 h-20 rounded"
                      alt=""
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openMediaModal && (
        <SelectMedia
          control={(media) => setImage(media[0])}
          close={() => setOpenMediaModal(false)}
        />
      )}
    </div>
  );
}
