import React, { useEffect, useState } from "react";
import { dateFormat } from "../../_helper/helper";

export default function InvoicePrint({ order }) {
  const [freeOnes, setFreeOnes] = useState([]);
  useEffect(() => {
    let free = [...order?.info?.map((item) => item?.get_y_products)];
    if (free[0]) {
      if (order?.coupon_promo && order?.coupon_promo?.products?.length > 0) {
        free = [...free, ...order?.coupon_promo?.products];
      }
      setFreeOnes(free[0]);
    }
  }, [order]);
  let shipment = {
    name: order?.billing_name,
    address: order?.billing_address,
    phone: order?.billing_phone,
    email: order?.billing_email,
    township: order?.billing_township?.name,
    city: order?.billing_city?.name,
  };
  if (order?.diff_area_status === 1) {
    shipment = {
      name: order?.shipping_name,
      address: order?.shipping_address,
      phone: order?.shipping_phone,
      email: order?.shipping_email,
      township: order?.shipping_township?.name,
      city: order?.shipping_city?.name,
    };
  }
  return (
    <>
      <div className="w-full grid grid-cols-2 mb-4">
        <div className="text-left">
          <img src="/image/logo.png" className="w-80" alt="" />
        </div>
        <div className="text-right">
          <p className="text-xl font-bold">OliveGo Home Improvement</p>
          <p>09880566420</p>
          <p className="whitespace-nowrap">
            Room (4,5,6), Building (36), Kyauk Sein 3rd Street, <br />
            Saw Bwar Gyi Kone Compound,
            <br />
            Insein Township, Yangon
          </p>
        </div>
      </div>
      <div className="font-bold grid grid-cols-2 w-max mb-4 gap-1">
        <p>Invoice </p>
        <p>: {order?.id}</p>
        <p>Order Number </p>
        <p>: {order?.order_number}</p>
        <p>Order Date </p>
        <p>: {dateFormat(order?.created_at, "full")}</p>
        <p>Payment Method </p>
        <p>: {order?.payment_method?.split("_")?.join(" ")}</p>
        <p>Shpping Method </p>
        <p>
          : {order?.diff_area_status === 1 ? "Drop Shipping" : "Door to door"}
        </p>
      </div>

      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-logo_color font-bold">
            <td className="text-center p-3 border border-[#000] border-b-0">
              Billing Address
            </td>
            <td className="text-center p-3 border border-[#000] border-b-0 border-l-0">
              Shipping Address
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left pl-2 border border-[#000] py-2">
              <ul className="flex flex-col gap-2">
                <li className="flex items-center">
                  <div className="font-bold w-20">Name</div>:
                  <span className="ml-4">{order?.billing_name}</span>
                </li>
                <li className="flex items-center">
                  <div className="font-bold w-20">Phone</div>:
                  <span className="ml-4">{order?.billing_phone}</span>
                </li>
                <li className="flex items-center">
                  <div className="font-bold w-20">Email</div>:
                  <span className="ml-4">{order?.billing_email}</span>
                </li>
                <li className="flex items-start">
                  <div className="font-bold min-w-[5rem]">Address</div>:
                  <span className="ml-4 text-left">
                    {order?.billing_address}, {order?.billing_township?.name},{" "}
                    {order?.billing_city?.name}
                  </span>
                </li>
              </ul>
            </td>
            <td className="text-left pl-2 border border-[#000] py-2">
              <ul className="flex flex-col gap-2">
                <li className="flex items-center">
                  <div className="font-bold w-20">Name</div>:
                  <span className="ml-4">{shipment.name}</span>
                </li>
                <li className="flex items-center">
                  <div className="font-bold w-20">Phone</div>:
                  <span className="ml-4">{shipment.phone}</span>
                </li>
                <li className="flex items-center">
                  <div className="font-bold w-20">Email</div>:
                  <span className="ml-4">{shipment.email}</span>
                </li>
                <li className="flex items-start">
                  <div className="font-bold min-w-[5rem]">Address</div>:
                  <span className="ml-4 text-left">
                    {shipment.address}, {shipment.township}, {shipment.city}
                  </span>
                </li>
                {order?.drop_shipping?.bus_gate && (
                  <li className="flex items-start">
                    <div className="font-bold w-20">Bus Gate</div>:
                    <span className="ml-4">
                      {order?.drop_shipping?.bus_gate}
                    </span>
                  </li>
                )}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="w-full border-collapse mt-4">
        <thead>
          <tr>
            <td className="text-left font-bold border pl-4 border-[#000] py-1">
              SKU Code
            </td>
            <td className="text-left font-bold border pl-4 border-[#000] py-1">
              Item
            </td>
            <td className="text-left font-bold border pl-4 border-[#000] py-1">
              Price
            </td>
            <td className="text-left font-bold border pl-4 border-[#000] py-1">
              Quantity
            </td>
            <td className="text-left font-bold border pl-4 border-[#000] py-1">
              Discount
            </td>
            <td className="text-left font-bold border pl-4 border-[#000] py-1">
              Total Price
            </td>
          </tr>
        </thead>
        <tbody>
          {order?.info?.map((item) => {
            const {
              sku: { product, price },
              summary_price,
              product_promo,
              quantity,
            } = item;
            return (
              <tr key={item.id}>
                <td className="text-left pl-4 py-1 border border-[#000] text-sm w-max">
                  {item?.sku?.code}
                </td>
                <td className="text-left pl-4 py-1 border border-[#000]">
                  {product?.name}
                </td>
                <td className="text-left pl-4 py-1 border border-[#000]">
                  {Number(price)?.toFixed(0)} Ks
                </td>
                <td className="text-left pl-4 py-1 border border-[#000]">
                  {quantity}
                </td>
                <td className="text-left pl-4 py-1 border border-[#000]">
                  {product_promo || 0}
                </td>
                <td className="text-left pl-4 py-1 border border-[#000]">
                  {summary_price} Ks
                </td>
              </tr>
            );
          })}

          <tr>
            <td colSpan="3"></td>
            <td
              colSpan="2"
              className="pl-4 font-semibold py-1 border border-[#000]"
            >
              Delivery Fees
            </td>
            <td className="text-left pl-4 py-1 border border-[#000]">
              {order?.delivery_price} Ks
            </td>
          </tr>
          <tr>
            <td colSpan="3"></td>
            <td
              colSpan="2"
              className="pl-4 font-semibold py-1 border border-[#000]"
            >
              Subtotal
            </td>
            <td className="text-left pl-4 py-1 border border-[#000]">
              {order?.summary_price} Ks
            </td>
          </tr>
          <tr>
            <td colSpan="3"></td>
            <td
              colSpan="2"
              className="pl-4 font-semibold py-1 border border-[#000]"
            >
              Grand Total
            </td>
            <td className="text-left pl-4 py-1 border border-[#000]">
              {order?.summary_price} Ks
            </td>
          </tr>
        </tbody>
      </table>

      {freeOnes?.length > 0 && (
        <>
          <h4 className="mt-4 font-semibold">Gift</h4>
          <table className="w-full border-collapse ">
            <thead>
              <tr>
                <td className="text-left font-bold border pl-4 border-[#000] py-1">
                  Item
                </td>
                <td className="text-left font-bold border pl-4 border-[#000] py-1">
                  SKU ID
                </td>
                <td className="text-left font-bold border pl-4 border-[#000] py-1">
                  Unit Price
                </td>
              </tr>
            </thead>
            <tbody>
              {freeOnes?.map((item) => (
                <tr key={item.sku_id}>
                  <td className="text-left pl-4 py-1 border border-[#000]">
                    {item.name}
                  </td>
                  <td className="text-left pl-4 py-1 border border-[#000] text-sm w-max">
                    {item.sku_id}
                  </td>
                  <td className="text-left pl-4 py-1 border border-[#000]">
                    {Number(item.price)?.toFixed(0)} Ks
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}
