import React from "react";

export default function LoadingListSkeleton({ columns = [], rows }) {
  return (
    <>
      {[...Array(rows).keys()]?.map((item) => (
        <tr key={item}>
          {columns.map((placeholder, index) => (
            <td key={index}>
              {["icon", "image"].includes(placeholder.name) ? (
                <div className="h-10 w-10 rounded-full bg-gray-200 animate-pulse"></div>
              ) : (
                <div className="w-full h-6 bg-gray-200 animate-pulse"></div>
              )}
            </td>
          ))}
          <td className="flex items-center justify-end gap-2">
            <div className="w-16 h-8 bg-gray-200 animate-pulse"></div>
            <div className="w-16 h-8 bg-gray-200 animate-pulse"></div>
          </td>
        </tr>
      ))}
    </>
  );
}
