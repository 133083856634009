import { lazy } from "react";
import ProtectedRoute from "../components/shared/ProtectedRoute";
import { Route, Routes } from "react-router-dom";
import DeliveryFees from "../pages/Region/DeliveryFees";
import CarGates from "../pages/Region/CarGate";

const Country = lazy(() => import("../pages/Region/Country"));
const Regions = lazy(() => import("../pages/Region/Regions"));
const Townships = lazy(() => import("../pages/Region/Township"));
const City = lazy(() => import("../pages/Region/City"));

export default function LocationRoutes() {
  return (
    <Routes>
      <Route
        path="/countries"
        element={
          <ProtectedRoute permission="view_country">
            <Country />
          </ProtectedRoute>
        }
      />
      <Route
        path="/regions"
        element={
          <ProtectedRoute permission="view_region">
            <Regions />
          </ProtectedRoute>
        }
      />
      <Route
        path="/townships"
        element={
          <ProtectedRoute permission="view_township">
            <Townships />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cities"
        element={
          <ProtectedRoute permission="view_city">
            <City />
          </ProtectedRoute>
        }
      />
      <Route
        path="/deli-fees"
        element={
          <ProtectedRoute permission="view_delivery_fees">
            <DeliveryFees />
          </ProtectedRoute>
        }
      />
      <Route
        path="/car-gates"
        element={
          <ProtectedRoute permission="view_cargate">
            <CarGates />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
