import React from "react";
import useRefetch from "../../hooks/useRefetch";
import { editDataFrom, postDataTo } from "../../_helper/fetcher";
import useButtons from "../../hooks/useButtons";
import Select from "react-select";
import { useState } from "react";
import UploadImage from "../shared/UploadImage";

const notiTypes = [
  { value: "default", label: "default" },
  { value: "promotion", label: "promotion" },
];

export default function NotificationForm({ data, close, type }) {
  const { refetchList } = useRefetch();
  const [image, setImage] = useState(null);
  const [notiType, setNotiType] = useState(
    data?.type
      ? notiTypes?.find((type) => type.value === data.type)
      : notiTypes[0]
  );
  const { CloseButton, SaveButton } = useButtons();
  async function controlTags(e) {
    e.preventDefault();
    const body = {
      type: notiType.value,
      title: e.target.title.value,
      body: e.target.body.value,
      media_id: image.id,
    };
    if (type === "edit") await editDataFrom("notifications/" + data.id, body);
    else await postDataTo("notifications", body);
    refetchList("notifications");
    close();
  }
  return (
    <form onSubmit={controlTags}>
      <div className="flex gap-4 items-center">
        <div className="w-full">
          <label className="form-label">Title</label>
          <input
            required
            name="title"
            className="name-input"
            type="text"
            id="forName"
            placeholder="Title"
            defaultValue={data?.title}
          />
        </div>
        <div className="w-full">
          <label className="form-label">Type</label>
          <Select
            onChange={(e) => setNotiType(e)}
            name="location"
            className="react-select capitalize"
            options={notiTypes}
            value={notiType}
          />
        </div>
      </div>
      <div>
        <label className="form-label">Body</label>
        <textarea
          required
          name="body"
          className="name-input"
          type="text"
          placeholder="Body"
          defaultValue={data?.body}
        />
      </div>
      <UploadImage
        uploaded={data.image}
        current={image}
        setImage={(image) => setImage(image)}
      />
      <div className="flex items-center justify-end mt-4">
        <CloseButton onClick={close} />
        <SaveButton />
      </div>
    </form>
  );
}
