import React from "react";

const teams = {
  qc: "QC",
  packing: "Packer",
  delivery: "Delivery Admin",
  delivered: "Admin",
  failed: "Admin",
};

export default function OperationNotes({ assigns = [] }) {
  if (assigns.length === 0) return "";
  return (
    <div className="mt-8">
      <h3 className="font-bold text-lg mb-4">Notes from operation teams</h3>
      <div className="grid grid-cols-3 gap-4">
        {assigns?.map((track) => (
          <div key={track.id} className="bg-gray-100 p-2">
            <h4 className="font-semibold text-base">
              {track?.assign_by?.name} to{" "}
              {track?.assign_to
                ? track?.assign_to.name
                : teams[track.status] + " team"}
            </h4>
            <p className="mt-2">Note : {track.note}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
