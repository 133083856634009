import { ClipLoader } from "react-spinners";

export default function LoadingSpinner() {
  return (
    <div className="absolute top-16 left-0 w-full h-[70vh] grid place-items-center">
      <ClipLoader
        color={"#b3c535"}
        loading={true}
        speedMultiplier={0.5}
        size={125}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
