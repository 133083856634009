import React, { useState } from "react";
import DeletePopUp from "../../components/popups/DeletePopUp";
import Pagination from "../../components/shared/Pagination";
import useQueryString from "../../hooks/useQueryString";

import { deleteDataFrom, fetchDataFrom } from "../../_helper/fetcher";
import TableList from "../../components/shared/TableList";
import FormModalContainer from "../../components/shared/FormModalContainer";
import { useQuery } from "react-query";
import useRefetch from "../../hooks/useRefetch";
import useButtons from "../../hooks/useButtons";
import CarGateForm from "../../components/formPopups/CarGateForm";

const columnsHead = [
  { name: "id.", accessor: ["id"] },
  { name: "city", accessor: ["city", "name"] },
  { name: "township", accessor: ["township", "name"] },
  { name: "car gate", accessor: ["bus_gate"] },
  { name: "price", accessor: ["price", "yesItsPrice"] },
];

export default function CarGates() {
  const [pagin, setPagin] = useState({});
  const [showModal, setShowModal] = useState({
    show: false,
    type: "create",
    data: {},
  });

  const [showDeleteModal, setShowDeleteModal] = useState({
    id: "",
    show: false,
  });

  const { queryString } = useQueryString();
  const { refetchList } = useRefetch();
  const { AddNewBtn } = useButtons();

  const getData = async () => {
    const res = await fetchDataFrom("drop-shipping-fees" + queryString);
    if (res.error) return null;
    const items = res.data;
    const { per_page, pages, total } = res.meta;
    setPagin({
      pages: pages,
      perPage: per_page,
      total: total,
    });
    return items;
  };

  const deleteCarGate = async () => {
    await deleteDataFrom("drop-shipping-fees/" + showDeleteModal.id);
    setShowDeleteModal({ id: "", show: false });
    refetchList("drops");
  };

  const {
    data: drops,
    isLoading,
    isError,
    error,
  } = useQuery(["drops", queryString], getData);
  return (
    <>
      <div className="px-2">
        <div className="box">
          <div className="box-content p-0">
            <div className="box-tools">
              <div className="box-title">
                <h3>Car Gates</h3>
                <AddNewBtn
                  permission="add_delivery_fee"
                  onClick={() =>
                    setShowModal({ show: true, type: "create", data: {} })
                  }
                  text="Add Car Gate"
                />
                {showModal.show && (
                  <FormModalContainer
                    title={
                      showModal.type === "create"
                        ? "New Car Gate"
                        : "Edit Car Gate"
                    }
                  >
                    <CarGateForm
                      type={showModal.type}
                      data={showModal.data}
                      close={() => setShowModal({ show: false })}
                    />
                  </FormModalContainer>
                )}
              </div>
            </div>

            <TableList
              onDelete={(item) => {
                setShowDeleteModal({
                  id: item.id,
                  show: true,
                });
              }}
              data={drops}
              columns={columnsHead}
              onEdit={(item) => {
                setShowModal({
                  show: true,
                  type: "edit",
                  data: item,
                });
              }}
              delPer="delete_car_gate"
              editPer="edit_car_gate"
              isLoading={isLoading}
              isError={isError}
              error={error}
            />
          </div>
        </div>
      </div>
      {showDeleteModal.show && (
        <DeletePopUp
          close={() => setShowDeleteModal({ id: "", show: false })}
          func={deleteCarGate}
          text={`Are you sure you want to delete?`}
        />
      )}

      {pagin?.perPage <= pagin?.total && <Pagination pagin={pagin} />}
    </>
  );
}
