import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import DeletePopUp from "../../components/popups/DeletePopUp";
import Filter from "../../components/order/ListFilter";
import Pagination from "../../components/shared/Pagination";
import TableList from "../../components/shared/TableList";

import useQueryString from "../../hooks/useQueryString";
import useRefetch from "../../hooks/useRefetch";

import { deleteDataFrom, fetchDataFrom } from "../../_helper/fetcher";
import AssignPicker from "../../components/order/AssignPicker";
import { toast } from "react-toastify";
import { makeItSelectable } from "../../_helper/helper";
import ConfirmOrCancelModal from "../../components/order/ConfirmOrCancelModal";
import useButtons from "../../hooks/useButtons";

const columnsHead = [
  { name: "customer's name", accessor: ["billing_name"] },
  { name: "customer's phone", accessor: ["billing_phone"] },
  { name: "delivery fee", accessor: ["delivery_price", "yesItsPrice"] },
  { name: "summary price", accessor: ["summary_price", "yesItsPrice"] },
  { name: "payment method", accessor: ["payment_method", "splitJoinFunc"] },
];

export default function OrdersList() {
  const [showDeleteModal, setShowDeleteModal] = useState({
    id: "",
    show: false,
  });
  const [showAssignModal, setShowAssignModal] = useState({
    show: false,
    id: null,
  });
  const [showConfirmModal, setShowConfirmModal] = useState({
    show: false,
    order: null,
    type: "confirm",
  });
  const [pagin, setPagin] = useState({});
  const [pickers, setPickers] = useState([]);

  const navigate = useNavigate();
  const { refetchList } = useRefetch();
  const { queryString } = useQueryString();

  const getData = async () => {
    const res = await fetchDataFrom("orders" + queryString);
    if (res.error) return null;
    const items = res.data;
    const { per_page, pages, total } = res.meta;
    setPagin({
      pages: pages,
      perPage: per_page,
      total: total,
    });
    return items;
  };
  const getReport = async () => {
    try {
      const res = await fetchDataFrom("orders/export-csv" + queryString);
      toast.success(
        res.message || 'Download the file in "Exported Orders" menu'
      );
    } catch (error) {
      toast.error("Failed to export CSV");
    }
  };

  async function getPickers() {
    try {
      const res = await fetchDataFrom("admins?role_id=13");
      setPickers(makeItSelectable(res.data));
    } catch (error) {
      toast.error(error.message);
    }
  }
  useEffect(() => {
    getPickers();
  }, []);

  const deleteProduct = async () => {
    await deleteDataFrom("orders/" + showDeleteModal.id);
    setShowDeleteModal({ id: "", show: false });
    refetchList("orders");
  };

  const { ExportButton } = useButtons();

  const {
    data: orders,
    isLoading,
    isError,
    error,
  } = useQuery(["orders", queryString], getData);
  const { mutate, isLoading: isExportLoading } = useMutation({
    mutationFn: getReport,
    onerror: () => toast.error("Failed to export orders report"),
  });
  return (
    <>
      <div className="px-2">
        <div className="box">
          <div className="box-content p-0">
            <div className="box-tools">
              <div className="box-title">
                <h3>Orders</h3>
              </div>
              <ExportButton
                permission="view_order"
                onClick={mutate}
                isLoading={isExportLoading}
              />
            </div>
            <Filter />
            <TableList
              editPer="edit_order"
              data={orders}
              columns={columnsHead}
              onDetail={(item) => navigate("/orders/" + item.id)}
              isLoading={isLoading}
              isError={isError}
              error={error}
              customColumns={[{ name: "status" }, { name: "Order Request" }]}
              customFirstColumns={[{ name: "Order Number" }]}
              customElements={[<Status />, <OrderRequest />]}
              customFirstElements={[<OrderNumber />]}
              // onCustomClick={handleProductClicked}
              customButton={
                <ConfirmOrAssign
                  onConfirm={(type, order) =>
                    setShowConfirmModal({ show: true, order, type })
                  }
                  onClickAssign={(id) => setShowAssignModal({ show: true, id })}
                  refetch={() => refetchList("orders")}
                />
              }
            />
          </div>
        </div>
      </div>
      {showAssignModal.show && (
        <AssignPicker
          pickers={pickers}
          orderID={showAssignModal.id}
          close={() => setShowAssignModal({ show: false, id: null })}
          refetch={() => refetchList("orders")}
        />
      )}
      {showConfirmModal.show && (
        <ConfirmOrCancelModal
          order={showConfirmModal.order}
          type={showConfirmModal.type}
          close={() =>
            setShowConfirmModal({ show: false, order: null, type: "confirm" })
          }
          refetch={() => refetchList("orders")}
        />
      )}
      {showDeleteModal.show && (
        <DeletePopUp
          close={() => setShowDeleteModal({ id: "", show: false })}
          func={deleteProduct}
          text="Click the 'Delete' button to delete this product"
        />
      )}

      {pagin?.perPage <= pagin?.total && <Pagination pagin={pagin} />}
    </>
  );
}

function ConfirmOrAssign({ item, onClickAssign, onConfirm }) {
  const { status, payment_method } = item;
  return (
    <>
      {status === "pending" && payment_method === "COD" && (
        <button
          onClick={() =>
            onConfirm("confirm", {
              id: item.id,
              order_number: item?.order_number,
            })
          }
          className="btn btn-sm btn-info mr-1"
        >
          Confrim
        </button>
      )}
      {status !== "reject" && (
        <button
          onClick={() =>
            onConfirm("cancel", {
              id: item.id,
              order_number: item?.order_number,
            })
          }
          className="btn btn-sm btn-danger mr-1"
        >
          Cancel
        </button>
      )}
      {["confirmed", "confirm"].includes(status) && (
        <button
          onClick={() => onClickAssign(item.id)}
          className="btn btn-sm btn-primary mr-1"
        >
          Assign Picker
        </button>
      )}
    </>
  );
}

function OrderRequest({ item }) {
  return <p>{item?.order_request_status === true ? "Yes" : "No"}</p>;
}
function OrderNumber({ item }) {
  return (
    <Link className="underline" to={`/orders/${item.id}`}>
      {item.order_number}
    </Link>
  );
}

export function Status({ item: { status } }) {
  let bgColor = "#FFA500";
  switch (status) {
    case "confirm":
      bgColor = "#21b121";
      break;
    case "picking":
      bgColor = "#1E90FF ";
      break;
    case "qc":
      bgColor = "#FFD700";
      break;
    case "packing":
      bgColor = "#00b3a4";
      break;
    case "delivering":
      bgColor = "#3232ff";
      break;
    case "delivered":
      bgColor = "#03d303";
      break;
    case "failed":
      bgColor = "#ff0000";
      break;
    case "cancelled":
      bgColor = "#ff0000";
      break;
    default:
      break;
  }
  return (
    <h2
      className="uppercase py-1 px-4 rounded-xl text-center text-white text-xs w-max"
      style={{ backgroundColor: bgColor }}
    >
      {status}
    </h2>
  );
}
