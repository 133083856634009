import { useEffect, useState } from "react";
import { fetchDataFrom } from "../../_helper/fetcher";
import { makeItSelectable } from "../../_helper/helper";

const useDetailLocations = (location) => {
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    fetchDataFrom(location).then((data) => {
      if (!data.error) setLocations(makeItSelectable(data));
    });
  }, [location]);
  return locations;
};

export default useDetailLocations;
