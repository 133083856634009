import { useState } from "react";
import useSidebarMenus from "../hooks/useSidebarMenus";
import Aside from "./Aside";
import Main from "./Main";
import { fetchDataFrom } from "../_helper/fetcher";
import { useEffect } from "react";
import { setAuth } from "../store/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function Layout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getAdmin() {
    const res = await fetchDataFrom("me");
    if (res?.user) {
      let user = res.user;
      const authBody = {
        permissions: user.role.permissions,
        name: user.name,
        email: user.email,
        isSuperAdmin: user.role.permissions[0] === "*",
        authorized: true,
      };
      dispatch(setAuth(authBody));
    } else {
      navigate("/login");
    }
  }

  useEffect(() => {
    getAdmin();
  }, []);

  const menus = useSidebarMenus();

  return (
    <div className="app overflow-x-hidden overflow-y-hidden">
      <Aside collapsed={collapsed} setCollapsed={setCollapsed} menus={menus} />
      <Main handleCollapsedChange={handleCollapsedChange} props={props}>
        {props.children}
      </Main>
    </div>
  );
}

const withCheckingLogin = (Component) => {
  return ({ pathname, ...props }) => {
    if (pathname === "/login") return <main>{props.children}</main>;
    return <Component {...props} />;
  };
};

export default withCheckingLogin(Layout);
